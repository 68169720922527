<template>
  <div class="header" id="head">
    <div class="h-box1"></div>
    <div class="h-box">
      <div class="h-logo">
        <a href="#">
          <img src="../../assets/img/sxLogo.png" alt="" width="225" />
        </a>
      </div>
      <div class="h-nav">
        <div class="h-nav-box">
          <div class="h-nav-item text-center hangkuai">
            <router-link
              to="/"
              :class="{'kuai':true,'header-text-color':true,'h-a-show':thisShow == 'index' }"
              @click="isShow('index')"
              >首页</router-link>
          </div>
          <div class="h-nav-item text-center hangkuai ">
            <router-link
              :class="{'kuai':true,'header-text-color':true,'h-a-show':thisShow == 'company' }"
              :to="{ name: 'company'}"
              @click="isShow('company')"
              >神仙网络</router-link
            >
          </div>
          <div class="h-nav-item text-center hangkuai ">
            <router-link
              href="#"
              :class="{'kuai':true,'header-text-color':true,'h-a-show':thisShow == 'goodscenter' }"
              :to="{ name: 'goodscenter'}"
              @click="isShow('goodscenter')"
              >产品中心</router-link
            >
          </div>
          <div class="h-nav-item text-center hangkuai ">
            <router-link
              href="#"
              :class="{'kuai':true,'header-text-color':true,'h-a-show':thisShow == 'solution' }"
              :to="{ name: 'solution' }"
              @click="isShow('solution')"
              >解决方案</router-link
            >
          </div>
          <div class="h-nav-item text-center hangkuai ">
            <router-link
              :class="{'kuai':true,'header-text-color':true,'h-a-show':thisShow == 'recruit' }"
              :to="{ name: 'recruit'}"
              @click="isShow('recruit')"
              >人事招聘</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Header",
  data() {
    return {
      // show: this.$route.query.show,
      thisShow: "index"
    };
  },
  methods: {
    TopWrapScroll: function () {
      window.addEventListener("scroll", function () {
        var TopWrapScroll = $("#top-wrap").outerHeight();
        if (document.documentElement.scrollTop >= TopWrapScroll) {
          $(function () {
            $("#head").css("background", "rgb(30, 159, 255,0.4)");
          });
        } else {
          $(function () {
            $("#head").css("background", "");
          });
        }
      });
    },
    isShow: function (pram){
      console.log(pram);
      this.thisShow = pram
      console.log(this.thisShow);
    }
  },
  mounted() {
    console.log("hahah");
    this.TopWrapScroll();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../../assets/css/public.css");
/* @import url("../../assets/css/commons/header.css"); */
.header {
  width: 100%;
  height: 80px;
  /* opacity: 0.1; */
  position: absolute;
  top: 0;
  z-index: 999;
}
.header-text-color {
  color: white;
}

.header:hover .h-box1 {
  opacity: 1;
  /* background: #409eff; */
  background-color: #094a99;
}

.h-box1 {
  /* background: rgb(30, 159, 255, 0.2); */
  width: 100%;
  height: 80px;
  transition: all 0.5s;
}

.h-box {
  width: 1200px;
  height: 60px;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
  top: -80px;
}

.h-logo {
  width: 240px;
  height: 100%;
  position: absolute;
  top: 0;
}

.h-nav {
  width: 800px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 310px;
  /* background-color: cadetblue; */
}

.h-nav-box {
  line-height: 60px;
}

.h-nav-item {
  width: 12%;
  height: 60px;
  position: relative;
}

.h-nav-item:hover a {
  margin: 0 auto;
  width: 60%;
  height: 92%;
  border-bottom: 4px solid white;
}

/* 导航选定标签 */

.h-a-show {
  margin: 0 auto;
  width: 60%;
  height: 92%;
  border-bottom: 4px solid white;
}
</style>
