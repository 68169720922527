<template>
  <div class="footer">
    <div class="footer-box wrap">
      <div class="auto-1200">
      <div class="f-left hangkuai white">
        <h3 class="title-h3"><i class="layui-icon layui-icon-service" style="font-size: 30px; color: rgb(30, 159, 255);"></i>  联系我们</h3>
        <div class="p-1"><span class="">客服电话</span>：{{companyInfo.phone}}</div>
        <div class="p-1"><span class="">人事邮箱</span>：{{companyInfo.personnel_email}}</div>
        <div class="p-1"><span class="">商务合作邮箱</span>：{{companyInfo.business_email}}</div>
        <div class="p-1"><span class="">公司地址</span>：{{companyInfo.address}}</div>
      </div>
      <div class="f-center hangkuai white text-center">
        <h3 class="title-h3"></h3>
      </div>
      <div class="f-right hangkuai white text-center">
        <div class="f-imgitem hangkuai">
          <div class="f-gzh">
            <img src="../../assets/img/weixin1.png" style="width:100%" alt="">
          </div>
          <p class="p-1 text-center">扫一扫关注公众号</p>
        </div>
      </div>
      <div style="clear: both;height=0"></div>
      <div class="f-buttom">
        <div class="f-btn-yum hangkuai">
          代理域名注册服务机构：烟台帝思普网络科技有限公司（DNSPod） 新网数码 广州云讯信息科技有限公司
        </div>
        <div class="f-btn-yum hangkuai " style="margin-left:20px">
          公司名称：厦门神仙网络科技有限公司  备案号：<a href="" class="white">闽ICP备15004769号</a>  网站备案号：闽ICP备15004769号-1
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      companyInfo:this.$store.state.companyInfo
    }}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* @import url('../../assets/css/commons/footer.css'); */
.footer {
    width: 100%;
    min-width: 1200px;
    /* height: 300px; */
    
    background-image: url(../../assets/78786.jpg);
    background-size: 100%;
    background-position-y: -315px;
    position: relative;
}
.footer-box{
  position: relative;
  background: #0045f3c9

}
.f-auto1200 {
    width: 1200px;
    margin: 0 auto;
}

.f-left {
    float: left;
    width: 40%;
    vertical-align: top;
}

.f-center {
    float: left;
    width: 30%;
    vertical-align: top;
}

.f-right {
    float: left;
    width: 30%;
    vertical-align: top;
}

.f-imgitem {
    padding-top: 10px;
}

.f-gzh {
    width: 150px;
    height: 150px;
    /* background-image: url(../../assets/img/weixin1.png); */
    /* background-size: 100% 100%; */
}

.f-buttom {
    margin-top: 20px;
    border-top: 1px solid #45484c;
}

.f-btn-yum {
    font-size: 12px;
    line-height: 40px;
    color: #ccc;
    white-space: nowrap;
}
.p-1 {
    line-height: 40px;
}
/* 1号标题 */

.title-h1 {
    font-size: 28px;
    line-height: 46px;
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
}

.title-h3 {
    font-size: 24px;
    line-height: 40px;
    font-weight: 900;
    -webkit-font-smoothing: antialiased;
}
</style>
