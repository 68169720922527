<template>
  <div class="service" v-if="flag">
    <div class="close" @click="close()" >
      <i class="layui-icon" style="font-size: 10px; color: #5fb878">&#x1006;</i>
    </div>
    <div class="erweima-list">
      <div class="erweima-wrap ">
        <div class="erweima backimg-weixin"></div>
        <p class="text-center">扫一扫联系客服</p>
      </div>
      <div class="erweima-wrap ">
        <div class="erweima backimg-qq"></div>
        <p class="text-center">扫一扫联系客服</p>
      </div>
      <div class="erweima-wrap">
        <p class="text-center p-home" v-for="item,index in companyInfo.Customer_managers" v-bind:key="index"><span>{{item.name}}</span>：{{item.number}}</p>
      </div>
    </div>

    <div class="communication_list">
      <div
        class="comm_item"
        @mouseover="erweima_show(0)"
        @mouseleave="erweima_noshow(0)"
      >
        <a href="#">
          <i id="weixin_icon" class="layui-icon" style="font-size: 50px;"
          >&#xe677;</i>

        </a>
      </div>
      <div class="comm_item"
      @mouseover="erweima_show(1)"
        @mouseleave="erweima_noshow(1)"
      >
        <a href="#">
          <i id="qq_icon" class="layui-icon" style="font-size: 50px;"
          >&#xe676;</i
        >
        </a>
      </div>
      <div class="comm_item"
      @mouseover="erweima_show(2)"
        @mouseleave="erweima_noshow(2)"
      >
        <a href="#">
          <i id="dianhua_icon" class="layui-icon" style="font-size: 50px;"
          >&#xe678;</i
        >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "Customer_service",
  data() {
    return {
      flag: true,
      companyInfo:this.$store.state.companyInfo
    };
  },
  methods: {
    erweima_show(index) {
      console.log(index);
      $("div.erweima-wrap").eq(index).css("visibility", "visible");
    },
    erweima_noshow(index) {
      $("div.erweima-wrap").eq(index).css("visibility", "hidden");
    },
    close(){
      this.flag = false
    }
  },
  mounted() {
    console.log($(".comm_item").index());
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("../../assets/css/commons/customer_service.css");
</style>
