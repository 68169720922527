import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


router.beforeEach((to, from, next) => {
    // 让页面回到顶部
    // chrome
    document.body.scrollTop = 0;
    // firefox
    document.documentElement.scrollTop = 0;
    // safari
    window.pageYOffset = 0;
    // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
    next()
})

// import $ from 'jquery'
// createApp(App).
createApp(App).use(store).use(router).mount('#app')