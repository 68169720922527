<template>
  <div>
    <!--头部导航-->
    <Header></Header>
    <!--主体内容-->

      <router-view ></router-view>

    <!--底部导航-->
    <Footer></Footer>
    <!-- 联系方式悬浮窗口 -->
    <Customer_service></Customer_service>
  </div>
  
</template>

<script>
import Header from './components/commons/Header'
import Footer from './components/commons/Footer'
import Customer_service from './components/commons/Customer_service'
export default {
  components:{
    Header,
    Footer,
    Customer_service
  },
  data(){
    return{
    } 
  },
  mounted(){
    console.log("auhd");
  },

}
</script>
<style>
*{
  margin: 0;
  padding: 0;
  text-decoration: none;
}

</style>
