import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
// import GoodsCenter from '../components/GoodsCenter.vue'

const routes = [{
    path: '/',
    name: 'index',
    component: () =>
        import ('@/components/Index.vue'),
    meta: {
        index: 1
    }
}, {
    path: '/recruit',
    name: 'recruit',
    component: () =>
        import ('@/components/Recruit.vue'),
    meta: {
        index: 2
    }
}, {
    path: '/jobinfo',
    name: 'JobInfo',
    component: () =>
        import ('@/components/JobInfo.vue'),
    meta: {
        index: 1
    }

}, {
    path: '/company',
    name: 'company',
    component: () =>
        import ('@/components/Company.vue'),
    meta: {
        index: 1
    }
}, {
    path: '/goodscenter',
    name: 'goodscenter',
    component: () =>
        import ('../components/GoodsCenter.vue'),
    meta: {
        index: 1
    }
}, {
    path: '/solution',
    name: 'solution',
    component: () =>
        import ('../components/Solution.vue'),
    meta: {
        index: 1
    }
}]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    mode: 'hash',
    routes

})


export default router